.App {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    box-sizing: initial;
}


:root {
    --thread-image-height: 15rem;
    --scrollbar-width: calc(100vw - 100%);
}

.gallery-open {
    overflow: hidden;
    padding-right: var(--scrollbar-compensation);
}